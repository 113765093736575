import { ChangeEvent, FC, useEffect, useState } from "react";
import Page, { PageBody, PageHead } from "../../ui/page/page";
import API, { setRequest } from "../../API";
import InputText from "../../ui/input/input";
import Button from "../../ui/button/button";
import Form, { FormActions, FormBody, FormCol, FormHeader, FormHr, FormRow } from "../../ui/form/form";
import Tile from "../../components/tile/tile";
import { useNavigate, useParams } from "react-router-dom";
import TicketsStore, { ICustomer, ITicketsItem } from "../../store/ticketsStore";
import Input from "../../ui/input/input";
import AppStore from "../../store/store";
import { IPlace } from "../../store/placesStore";
import { observer } from "mobx-react-lite";
import Image from "../../ui/image/image";
import Table from "../../ui/table/table";
import Modal from "../../ui/modal/modal";
import { getFormattedDate } from "../../helper";
import Notif from "../../components/notif/notif";
import NotifStore from "../../store/_notifStore";

interface Props {

}

const Tickets_Edit_Page: FC<Props> = observer((props: Props) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState<boolean>(false)
    const [inited, setInited] = useState<boolean>(false)
    const [modalActive, setModalActive] = useState<boolean>(false)
    const [sendTransferLoading, setSendTransferLoading] = useState<boolean>(false)
    const [places, setPlaces] = useState<IPlace[]>([]);
    const [tickets, setTickets] = useState<ITicketsItem[]>([]);
    const [selectedPlace, setSelectedPlace] = useState<IPlace>({} as IPlace);
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(null);

    useEffect(() => {
        load()
    }, [])

    useEffect(() => {
        if (!places.length) return
        loadTicket()
    }, [places])

    const loadTicket = () => {
        setInited(false)
        setRequest({
            type: 'GET',
            url: `/tickets/?id=${id}`,
            success: (payload) => {
                TicketsStore.setEditItemFull(payload.data)
                setSelectedPlace(places.filter(place => payload.data.place_id === place.id)[0])
                setLoaded(true)
                setInited(true)
            },
            error: (err) => {
                setLoaded(false)
                // setPlaces([])
            }
        })
    }
    
    useEffect(() => {
        if(!TicketsStore.editItem.properties?.sport) return
        setLoaded(false)
        setRequest({
            type: 'GET',
            url: `/places/?typeId=${TicketsStore.editItem.properties?.sport}`,
            success: (payload) => {
                setPlaces(payload.data)
                setInited(true)
                setLoaded(true)
            },
            error: (err) => {
                setPlaces([])
            }
        })
    }, [TicketsStore.editItem.properties?.sport])

    const load = () => {
        setLoaded(false)
        setRequest({
            type: 'GET',
            url: '/tickets/',
            success: (payload) => {
                setTickets(payload.data)
            },
            error: (err) => {
                setTickets([])
            }
        })
    }

    const selectPlace = (id: number) => {
        let place: IPlace = places.filter(place => place.id === id)[0]
        if (place) {
            TicketsStore.setEditItem('count', place.ticketCount)
            TicketsStore.setEditItem('place_id', place.id)
            // TicketsStore.setEditItem('price', place.)
            setSelectedPlace(place)
        }
    }

    const onSubmit = () => {
        setLoaded(false)
        setRequest({
            type: "POST",
            url: '/tickets/',
            data: TicketsStore.editItem,
            success: (payload) => {
                setLoaded(true)
                TicketsStore.setEditItemFull(payload.data)
                NotifStore.setSuccess('Игра обновлена')
                console.log(payload)
            },
            error: err => {
                NotifStore.setWarning('Ошибка обновления игры')
            }
        })
    }

    const bookedManualChange = (number: number) => {
        TicketsStore.setEditItem('correction', TicketsStore.editItem.correction + number)
    }

    const sendTransfer = () => {
        setSendTransferLoading(true)

        setRequest({
            type: 'POST',
            url: '/transfers/',
            data: {
                item_id: selectedCustomerId,
                ticket_id: TicketsStore.getEditItemCustomerById(selectedCustomerId)?.ticket_id
            },
            success: (payload) => {
                setModalActive(false)
                setSendTransferLoading(false)
                loadTicket()
                NotifStore.setSuccess('Трансфер отправлен')
            },
            error: (err) => {
                NotifStore.setWarning('Ошибка трансфера')
                setSendTransferLoading(false)
            }
        })
    }
    // if (!id) return;

    // return <></>

    return (
        <Page>
            <PageHead
                title={"Изменить игру"}
                info={"#" + id}
            />
            <PageBody>
                <Tile
                    className="ticketsAdd"
                    loaded={inited}
                    loader={true}
                >
                    <Form asModule={false}>
                        <FormBody>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Вид спорта"}
                                        value={TicketsStore.editItem.properties?.sport}
                                        options={AppStore.getProperty('sport').list}
                                        onChange={(id) => TicketsStore.setEditItemProperties('sport', id)}
                                        disabled={true}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Сезон"}
                                        value={TicketsStore.editItem.properties?.season}
                                        options={AppStore.getProperty('season').list}
                                        onChange={(id) => TicketsStore.setEditItemProperties('season', id)}
                                        disabled={true}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Площадка"}
                                        value={selectedPlace.id}
                                        options={places}
                                        onChange={selectPlace}
                                        disabled={true}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="text"
                                        label={"Старая цена"}
                                        value={TicketsStore.editItem.price_old}
                                        onChange={value => TicketsStore.setEditItem('price_old', value)}
                                        disabled={true}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="text"
                                        label={"Цена"}
                                        value={TicketsStore.editItem.price}
                                        onChange={value => TicketsStore.setEditItem('price', value)}
                                        disabled={true}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="datetime"
                                        format="dd.MM.yyyy hh:mm"
                                        label={"Дата начала"}
                                        value={TicketsStore.editItem.date_start}
                                        hourList={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]}
                                        minuteList={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]}
                                        onChange={value => {
                                            TicketsStore.setEditItem('date_start', value)
                                        }}
                                        disabled={true}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="datetime"
                                        format="dd.MM.yyyy hh:mm"
                                        label={"Дата окончания"}
                                        value={TicketsStore.editItem.date_end}
                                        hourList={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]}
                                        minuteList={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]}
                                        onChange={value => {
                                            TicketsStore.setEditItem('date_end', value)
                                        }}
                                        disabled={true}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Опции"}
                                        value={TicketsStore.editItem.properties?.option}
                                        options={AppStore.getProperty('option').list}
                                        onChange={(id) => TicketsStore.setEditItemProperties('option', id)}
                                        // disabled={true}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Статус"}
                                        value={TicketsStore.editItem.properties?.status}
                                        options={AppStore.getProperty('status').list}
                                        onChange={(id) => TicketsStore.setEditItemProperties('status', id)}
                                        disabled={true}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormHr />
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="text"
                                        label={"Всего мест"}
                                        value={function () {
                                            let z = '';
                                            let value = 0
                                            TicketsStore.editItem.correction > 0 ? z = "-" : z = "+";
                                            value = Math.abs(TicketsStore.editItem.correction)

                                            return `${TicketsStore.editItem.count}${value !== 0 ? ` ${z} ${value}` : ''}`
                                        }()}
                                        onChange={value => TicketsStore.setEditItem('count', value)}
                                        disabled={true}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="text"
                                        label={"Проданных мест"}
                                        value={TicketsStore.editItem.sold}
                                        onChange={value => TicketsStore.setEditItem('sold', value)}
                                        disabled={true}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="text"
                                        label={"Забронированных мест"}
                                        value={TicketsStore.editItem.booked}
                                        onChange={value => TicketsStore.setEditItem('booked', value)}
                                        disabled={true}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="text"
                                        label={"Доступно мест"}
                                        value={TicketsStore.editItem.available}
                                        onChange={value => TicketsStore.setEditItem('available', value)}
                                        disabled={true}
                                    />
                                </FormCol>
                                <FormCol asRow={true}>
                                    <Input
                                        type="text"
                                        label={"Коррекция мест"}
                                        value={TicketsStore.editItem.correction}
                                        onChange={value => TicketsStore.setEditItem('correction', value)}
                                        disabled={true}
                                    />
                                    <Button type="primary"
                                        icon="plus"
                                        // disabled={TicketsStore.editItem.count <= TicketsStore.editItem.booked_manual + TicketsStore.editItem.booked}
                                        onClick={() => {
                                            bookedManualChange(1)
                                        }}></Button>
                                    <Button type="primary" icon="minus" color="red" onClick={() => {
                                        bookedManualChange(-1)
                                    }}></Button>
                                </FormCol>
                            </FormRow>
                        </FormBody>
                        <FormActions>
                            <Button type="primary" onClick={onSubmit} icon="check" loading={!loaded}>Применить</Button>
                            <Button type="secondary" onClick={() => navigate('/tickets')}>Отменить</Button>
                            <div style={{ flexGrow: 1 }} />
                            <Button type="primary" color="purple" icon="refresh-cw" onClick={load} loading={!loaded}>Обновить</Button>
                        </FormActions>
                    </Form>
                </Tile>
                <Tile
                    className="ticketCustomerList"
                    title={'Список покупателей ' + (TicketsStore.editItem?.customers?.length || '0')}
                    loaded={inited}
                    loader={true}
                >
                    <Table
                        thead={[
                            {
                                title: '#',
                            },
                            {
                                title: 'ФИ',
                                // width: '40%'
                            },
                            {
                                title: 'Кол-во',
                            },
                            {
                                title: 'Телефон',
                            },
                            {
                                title: 'Email',
                            },
                            {
                                title: 'Промокод',
                            },
                            {
                                title: '',
                                width: '10%'
                            },
                        ]}
                        tbody={TicketsStore.editItem.customers?.map((customer, i) => [
                            (i + 1),
                            `${customer.last_name} ${customer.name}`,
                            customer.count,
                            customer.phone,
                            customer.email,
                            customer.promocode || '-',
                            // <Input
                            //     type="select"
                            //     value={customer.ticket_id}
                            //     options={getOpts()}
                            //     onChange={(id) => {
                            //         TicketsStore.setEditItemCustomer('ticket_id', customer.id, id)
                            //     }}
                            //     label={"Выберите билет"}
                            // />,
                            <Button type="primary" onClick={() => {
                                setModalActive(true)
                                setSelectedCustomerId(customer.id)
                            }}>Перенести</Button>
                        ])}
                    />
                </Tile>
            </PageBody>
            <Modal
                isActive={modalActive}
                title={`Перенос игрока #${selectedCustomerId}`}
                setActive={setModalActive}
            >
                <Form asModule={false}>
                    <FormBody>
                        <FormRow>
                            <FormCol>
                                <Input
                                    type="select"
                                    label={"Выберите билет"}
                                    value={TicketsStore.getEditItemCustomerById(selectedCustomerId)?.ticket_id}
                                    options={tickets.map(ticket => ({ id: ticket.id, title: `${getFormattedDate(ticket.date_start * 1000, 'dd.MM.yyyy hh:mm')} - ${ticket.place_title}` }))}
                                    onChange={(id) => {
                                        console.log(id)
                                        TicketsStore.setEditItemCustomer('ticket_id', selectedCustomerId, id)
                                    }}
                                />
                            </FormCol>
                        </FormRow>
                        <FormActions>
                            <Button
                                type="primary"
                                fGrow={true}
                                onClick={() => { sendTransfer() }}
                                loading={sendTransferLoading}
                            >
                                Применить
                            </Button>
                        </FormActions>
                    </FormBody>
                </Form>

            </Modal>
        </Page >
    )
})

export default Tickets_Edit_Page