import { ChangeEvent, FC, useEffect, useState } from "react";
import Page, { PageBody, PageHead } from "../../ui/page/page";
import API, { setRequest } from "../../API";
import InputText from "../../ui/input/input";
import Button from "../../ui/button/button";
import Form, { FormActions, FormBody, FormCol, FormHeader, FormHr, FormRow } from "../../ui/form/form";
import Tile from "../../components/tile/tile";
import { useNavigate, useParams } from "react-router-dom";
import TicketsStore, { ICustomer, ITicketsItem } from "../../store/ticketsStore";
import Input from "../../ui/input/input";
import AppStore from "../../store/store";
import PlacesStore, { IPlace } from "../../store/placesStore";
import { observer } from "mobx-react-lite";
import { getFormattedDate } from "../../helper";
import Notif from "../../components/notif/notif";
import NotifStore from "../../store/_notifStore";
import Gallery from "../../components/gallery/gallery";
import YandexMap from "../../components/map/yandexMap";


interface Props {

}

const Places_Add_Page: FC<Props> = observer((props: Props) => {
    const navigate = useNavigate();

    const [loaded, setLoaded] = useState<boolean>(true)
    const [inited, setInited] = useState<boolean>(false)
    const [mapLoaded, setMapLoaded] = useState<boolean>(false)
    const [mapOpened, setMapOpened] = useState<boolean>(false)

    useEffect(() => {
        PlacesStore.setEditItemTemplate()
        PlacesStore.setEditItemError()
    }, [])

    const onSubmit = () => {
        setLoaded(false)
        setRequest({
            type: 'POST',
            url: `/places/add/`,
            data: PlacesStore.editItem,
            success: (payload) => {
                console.log(payload)
                PlacesStore.setEditItemError()
                NotifStore.setSuccess('Площадка успешно добавлена', 'Площадка #Новая')
                setLoaded(true)
                navigate('/places/')
                // setInited(true)
            },
            error: (payload) => {
                if (payload.errors) {
                    PlacesStore.setEditItemError(payload.errors)
                    setMapOpened(true)
                }
                NotifStore.setWarning('Ошибка добавления площадки', 'Площадка #Новая')
                setLoaded(true)
                // setPlaces([])
            }
        })
    }

    // if (!id) return;

    // return <></>

    return (
        <Page>
            <PageHead
                title={"Добавить площадку"}
                info={"Новая"}
            />
            <PageBody>
                <Tile
                    className="ticketsAdd"
                    loaded={true}
                // loader={true}
                >
                    <Form asModule={false}>
                        <FormBody>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="text"
                                        label={"Название площадки"}
                                        value={PlacesStore.editItem.title}
                                        onChange={(id) => PlacesStore.setEditItem('title', id)}
                                        error={PlacesStore.editItemError.title}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Тип игры"}
                                        value={PlacesStore.editItem.typeId}
                                        options={AppStore.getProperty('sport').list}
                                        onChange={value => PlacesStore.setEditItem('typeId', value)}
                                        error={PlacesStore.editItemError.typeId}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="number"
                                        label={"Базовая цена"}
                                        value={PlacesStore.editItem.price}
                                        onChange={(id) => PlacesStore.setEditItem('price', id)}
                                        error={PlacesStore.editItemError.price}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormRow>
                                <FormCol>

                                    <Gallery
                                        items={PlacesStore.editItem.gallery}
                                        setItems={(items) => { PlacesStore.setEditItem('gallery', items) }}
                                    />
                                    {/* <Input
                                        type="select"
                                        label={"Площадка"}
                                        value={selectedPlace.id}
                                        options={places}
                                        onChange={selectPlace}
                                        disabled={true}
                                    /> */}
                                </FormCol>
                            </FormRow>
                            {!mapOpened &&
                                <>
                                    <FormRow>
                                        <FormCol>
                                            <Input
                                                type="textarea"
                                                label={"Адрес вручную"}
                                                value={PlacesStore.editItem.address}
                                                onChange={(id) => PlacesStore.setEditItem('address', id)}
                                                rows={4}
                                                minRows={4}
                                                maxRows={4}
                                                error={PlacesStore.editItemError.address}
                                            />
                                        </FormCol>

                                        <FormCol>
                                            <Input
                                                type="text"
                                                label={"Адрес на карте"}
                                                value={PlacesStore.editItem.addressMap}
                                                error={PlacesStore.editItemError.addressMap}
                                                onChange={() => { }}
                                                disabled={true}
                                            />
                                            <Button
                                                type="primary"
                                                onClick={() => (setMapOpened(true))}
                                                icon="map"
                                            >
                                                Открыть карту
                                            </Button>
                                        </FormCol>
                                    </FormRow>
                                </>
                            }
                            {mapOpened &&
                                <FormRow>
                                    <FormCol>
                                        <FormCol>
                                            <Input
                                                type="textarea"
                                                label={"Адрес вручную"}
                                                value={PlacesStore.editItem.address}
                                                onChange={(id) => PlacesStore.setEditItem('address', id)}
                                                error={PlacesStore.editItemError.address}
                                            />
                                        </FormCol>
                                        <Input
                                            type="textarea"
                                            label={"Адрес"}
                                            value={PlacesStore.editItem.addressMap}
                                            error={PlacesStore.editItemError.addressMap}
                                            onChange={() => { }}
                                            disabled={true}
                                            rows={3}
                                        />
                                        <FormRow>
                                            <FormCol>
                                                <Input
                                                    type="text"
                                                    label={"Широта"}
                                                    value={PlacesStore.editItem.latitude}
                                                    error={PlacesStore.editItemError.latitude}
                                                    onChange={() => { }}
                                                    disabled={true}
                                                />
                                            </FormCol>
                                            <FormCol>
                                                <Input
                                                    type="text"
                                                    label={"Долгота"}
                                                    value={PlacesStore.editItem.longitude}
                                                    error={PlacesStore.editItemError.longitude}
                                                    onChange={() => { }}
                                                    disabled={true}
                                                />
                                            </FormCol>
                                        </FormRow>
                                        <div style={{flexGrow: 1}} />
                                        <Button type="indicator" loading={!mapLoaded} disabled={true}>&nbsp;</Button>
                                        <Button type="secondary" icon="x" onClick={() => setMapOpened(false)}>Закрыть карту</Button>
                                    </FormCol>
                                    <FormCol>
                                        <YandexMap
                                            coords={{
                                                latitude: PlacesStore.editItem.latitude,
                                                longitude: PlacesStore.editItem.longitude,
                                                address: PlacesStore.editItem.address
                                            }}
                                            setAddress={(address) => {
                                                PlacesStore.setEditItem('latitude', address.latitude)
                                                PlacesStore.setEditItem('longitude', address.longitude)
                                                PlacesStore.setEditItem('addressMap', address.address)
                                                if (!PlacesStore.editItem.address) {
                                                    PlacesStore.setEditItem('address', address.address)
                                                }
                                            }}
                                            setMapLoad={setMapLoaded}
                                        />
                                    </FormCol>
                                </FormRow>
                            }
                            <FormHr />
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Тип поля"}
                                        value={PlacesStore.editItem.properties.area}
                                        options={AppStore.getProperty('area').list}
                                        onChange={value => PlacesStore.setEditItemProperties('area', value)}
                                        error={PlacesStore.editItemError.area}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Формат игры"}
                                        value={PlacesStore.editItem.properties.format}
                                        options={AppStore.getProperty('format').list}
                                        onChange={value => PlacesStore.setEditItemProperties('format', value)}
                                        error={PlacesStore.editItemError.format}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="select"
                                        label={"Метро"}
                                        value={PlacesStore.editItem.properties.metro}
                                        options={AppStore.getProperty('metro').list}
                                        onChange={value => PlacesStore.setEditItemProperties('metro', value)}
                                        error={PlacesStore.editItemError.metro}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="multiselect"
                                        label={"Инфраструктура"}
                                        value={PlacesStore.editItem.properties.infrastructure}
                                        options={AppStore.getProperty('infrastructure').list}
                                        onChange={value => PlacesStore.setEditItemProperties('infrastructure', value)}
                                        error={PlacesStore.editItemError.infrastructure}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormHr />
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="number"
                                        label={"Количество команд"}
                                        value={PlacesStore.editItem.teamCount}
                                        onChange={value => PlacesStore.setEditItem('teamCount', value)}
                                        error={PlacesStore.editItemError.teamCount}
                                    />
                                </FormCol>
                                <FormCol>
                                    <Input
                                        type="number"
                                        label={"Количество билетов"}
                                        value={PlacesStore.editItem.ticketCount}
                                        onChange={value => PlacesStore.setEditItem('ticketCount', value)}
                                        error={PlacesStore.editItemError.ticketCount}
                                    />
                                </FormCol>
                            </FormRow>
                            <FormRow>
                                <FormCol>
                                    <Input
                                        type="textarea"
                                        label="Описание площадки"
                                        value={PlacesStore.editItem.description}
                                        onChange={value => PlacesStore.setEditItem('description', value)}
                                        error={PlacesStore.editItemError.description}
                                    />
                                </FormCol>
                            </FormRow>
                        </FormBody>
                        <FormActions>
                            <Button type="primary" onClick={onSubmit} icon="check" loading={!loaded}>Добавить</Button>
                            <Button type="secondary" onClick={() => navigate('/places')}>Отменить</Button>
                        </FormActions>
                    </Form>
                </Tile>
            </PageBody>
        </Page >
    )
})

export default Places_Add_Page