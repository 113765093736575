import { observer } from "mobx-react-lite";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { setRequest } from "../../../API";
import ReportsStore, { IReportCustomer } from "../../../store/reportsStore";
import NotifStore from "../../../store/_notifStore";
import Page, { PageBody, PageHead } from "../../../ui/page/page";
import Form, { FormActions, FormBody, FormCol, FormHr, FormRow } from "../../../ui/form/form";
import Input from "../../../ui/input/input";
import Tile from "../../../components/tile/tile";
import Button from "../../../ui/button/button";
import AppStore, { IPropertyItem } from "../../../store/store";
import Table from "../../../ui/table/table";
import Modal from "../../../ui/modal/modal";


interface Props {
}
interface C extends IPropertyItem {
    customer: IReportCustomer
}

const Reports_Edit_Stats_Page: FC<Props> = observer((props: Props) => {
    const navigate = useNavigate();
    const [modalActive, setModalActive] = useState<boolean>(false);
    const [options, setOptions] = useState<C[]>([])
    const [option, setOption] = useState<number>()
    const [text, setText] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const { cancel, loaded, onSubmit } = useOutletContext<{ cancel: () => void, loaded: boolean, onSubmit: any }>()
    // TODO SMART LOADING
    const getOptions = (str: string) => {
        setLoading(true)
        setOptions([])
        setRequest({
            type: 'GET',
            url: `/customers/search/?query=${str}`,
            success: payload => {
                console.log(payload)
                let customerArr = payload.data.map((customer: IReportCustomer) => {
                    return {
                        id: customer.id,
                        title: `${customer.surname} ${customer.name} ${customer.phone}`,
                        customer: customer
                    }
                })
                setOptions(customerArr)
                setLoading(false)
            },
            error: (err) => {
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        if (text) {
            getOptions(text)
        } else {
            setOptions([])
        }
    }, [text])

    const getPlayer = (text: string) => {
        if (text) {
            getOptions(text)
        } else {
            setOptions([])
        }
    }

    const setCustomer = (customer: IReportCustomer) => {
        setModalActive(false)
        setOption(undefined)
        setOptions([])

        ReportsStore.addEditItemStat(customer)
    }

    return (
        <>
            <Form asModule={false}>
                <FormBody>

                    <Table
                        thead={[
                            {
                                title: 'id',
                                width: '50px',
                            },
                            {
                                title: 'Игрок',
                                // width: '20%',
                            },
                            {
                                title: 'Команда',
                                width: '160px',
                            },
                            {
                                title: 'Амплуа',
                                width: '200px',
                            },
                            {
                                title: 'Гол',
                                width: '44px',
                                align: 'center'
                            },
                            {
                                title: 'Пас',
                                width: '44px',
                                align: 'center'
                            },
                            {
                                title: 'Проп.',
                                width: '44px',
                                align: 'center'
                            },
                            {
                                title: 'Лучшие игроки',
                                width: '26%'
                            },
                            {
                                title: '',
                                width: '40px',
                                align: 'center'
                            },
                        ]}
                        tbody={ReportsStore.editItem.stats.map((stat, i) => [
                            `${stat.customer.id}`,
                            `${stat.customer.surname} ${stat.customer.name}`,
                            <Input
                                type="select"
                                value={stat.team}
                                options={AppStore.getProperty('team').list}
                                onChange={value => ReportsStore.setEditItemPropertiesStats(i, 'team', value)}
                                disableIconColor={true}
                            />,
                            <Input
                                type="select"
                                value={stat.role}
                                options={AppStore.getProperty('role').list}
                                onChange={value => ReportsStore.setEditItemPropertiesStats(i, 'role', value)}
                            />,
                            <Input
                                align="center"
                                type="text"
                                value={stat.goal}
                                onChange={value => ReportsStore.setEditItemPropertiesStats(i, 'goal', value)}
                            />,
                            <Input
                                align="center"
                                type="text"
                                value={stat.pass}
                                onChange={value => ReportsStore.setEditItemPropertiesStats(i, 'pass', value)}
                            />,
                            <Input
                                align="center"
                                type="text"
                                value={stat.missed}
                                onChange={value => ReportsStore.setEditItemPropertiesStats(i, 'missed', value)}
                            />,
                            <Input
                                type="multiselect"
                                value={stat.best}
                                options={AppStore.getProperty('best').list}
                                onChange={value => ReportsStore.setEditItemPropertiesStats(i, 'best', value)}
                            />,

                            <div className="actions">
                                <Button type="primary" icon="trash" color="red" onClick={() => { ReportsStore.removeEditItemStat(i) }}></Button>
                            </div>
                        ])}
                    />
                    <FormHr />
                </FormBody>
                <FormActions>
                    <Button type="primary" onClick={onSubmit} icon="check" loading={!loaded}>Применить</Button>
                    <Button type="secondary" onClick={cancel}>Отменить</Button>
                    <div style={{ flexGrow: 1 }} />
                    <Button type="primary" icon="plus" color="purple" onClick={() => { setModalActive(true) }}>Добавить</Button>
                </FormActions>
            </Form>
            <Modal
                isActive={modalActive}
                title={`Добавление игрока`}
                setActive={setModalActive}
                isHideBlur
            >
                <Form asModule={false}>
                    <FormBody>
                        <FormRow>
                            <FormCol>
                                <Input
                                    type="select"
                                    label={"Имя / Фамилия / Телефон"}
                                    value={option}
                                    options={options}
                                    onInput={val => {
                                        console.log(val)
                                        getPlayer(val)
                                    }}
                                    onChange={(id) => {
                                        // debugger
                                        setOption(id)
                                        // console.log(id)
                                        // TicketsStore.setEditItemCustomer('ticket_id', selectedCustomerId, id)
                                    }}
                                />
                            </FormCol>
                        </FormRow>
                        <FormActions>
                            <Button
                                type="primary"
                                fGrow={true}
                                loading={loading}
                                disabled={!option}
                                onClick={() => {
                                    console.log(options)
                                    let cust = options.filter(cust => cust.id === option)[0]
                                    console.log(cust)
                                    setCustomer(cust.customer)
                                }}
                            // loading={sendTransferLoading}
                            >
                                Добавить
                            </Button>
                        </FormActions>
                    </FormBody>
                </Form>

            </Modal>
        </>
    )
})

export default Reports_Edit_Stats_Page