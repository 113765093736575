import { action, makeAutoObservable, makeObservable, observable } from "mobx";
import ListStore, { IPagination } from "./_listStore";
import { IStatus } from "../components/status/status";
import { IGalleryImage } from "../components/gallery/gallery";



export interface IPlace {
    address: string,
    id: number,
    latitude: number,
    longitude: number,
    properties: { metro: number, infrastructure: number[], format: number, area: number },
    teamCount: number,
    ticketCount: number,
    ticketPrice: number,
    status: IStatus,
    title: string,
    typeId?: number
}
export interface IPlaceDetailGalleryItem extends IGalleryImage {
}
export interface IPlaceDetail {
    id: number | null,
    address: string
    addressMap: string,
    gallery: IPlaceDetailGalleryItem[]
    latitude: number | null
    longitude: number | null
    preview: string
    teamCount: number | null
    ticketCount: number | null
    title: string
    price: number | null
    description: string
    typeId?: number
    properties: {
        area: number | null,
        format: number | null,
        infrastructure: number[] | null,
        metro: number | null,
    }
}

export interface IPlaceDetailError {
    id: string,
    address: string,
    addressMap: string,
    latitude: string,
    gallery: string,
    longitude: string,
    preview: string,
    teamCount: string,
    ticketCount: string,
    title: string,
    price: string,
    description: string,
    area: string,
    format: string,
    infrastructure: string,
    metro: string,
    typeId?: string
}

export const TPlace: IPlaceDetail = {
    id: null,
    address: '',
    addressMap: '',
    gallery: [],
    latitude: null,
    longitude: null,
    preview: '',
    teamCount: null,
    ticketCount: null,
    title: '',
    price: null,
    description: '',
    properties: {
        area: null,
        format: null,
        infrastructure: null,
        metro: null,
    }
}



interface IPlacesStore {
    items: IPlace[],
    editItem: IPlaceDetail
    editItemError: IPlaceDetailError
}

class _PlacesStore extends ListStore implements IPlacesStore {
    items = [] as IPlace[]
    editItem = {
        gallery: [] as IPlaceDetailGalleryItem[],
        properties: {}
    } as IPlaceDetail
    editItemError = {
    } as IPlaceDetailError
    constructor() {
        super();
        this.name = '__Places__'
        makeObservable(this, {
            items: observable,
            editItem: observable,
            setItems: action,
        })
    }
    setItems = (items: IPlace[]) => {
        this.items = items;
    }
    setEditItemFull = (item: IPlaceDetail) => {
        this.editItem = item
    }
    setEditItemTemplate = () => {
        this.editItem = TPlace
    }
    setEditItem = <K extends keyof IPlaceDetail>(
        field: K,
        value: IPlaceDetail[K]) => {
        console.log(field, value);
        this.editItem[field] = value
        // @ts-ignore
        this.editItemError[field] = ''
    }
    setEditItemProperties = <K extends keyof IPlaceDetail['properties']>(
        field: K,
        value: IPlaceDetail['properties'][K]) => {
        this.editItem.properties[field] = value
        this.editItemError[field] = ''
    }
    setEditItemError = (item?: IPlaceDetailError) => {
        if (item) {
            this.editItemError = item
        } else {
            this.editItemError = {} as IPlaceDetailError
        }
    }
}

const PlacesStore = new _PlacesStore()

export default PlacesStore;