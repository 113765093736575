import { observer } from "mobx-react-lite";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { setRequest } from "../../../API";
import ReportsStore from "../../../store/reportsStore";
import NotifStore from "../../../store/_notifStore";
import Page, { PageBody, PageHead } from "../../../ui/page/page";
import Form, { FormActions, FormBody, FormCol, FormHr, FormRow } from "../../../ui/form/form";
import Input from "../../../ui/input/input";
import Tile from "../../../components/tile/tile";
import Button from "../../../ui/button/button";
import AppStore from "../../../store/store";
import Table from "../../../ui/table/table";


interface Props {
}

const Reports_Edit_Games_Page: FC<Props> = observer((props: Props) => {
    const navigate = useNavigate();
    const { cancel, loaded, onSubmit } = useOutletContext<{ cancel: () => void, loaded: boolean, onSubmit: any }>()

    return (
        <Form asModule={false}>
            <FormBody>
                <FormRow>
                    <FormCol>
                        <Input
                            type="select"
                            value={ReportsStore.editItem.winner}
                            label="Команда победитель"
                            options={ReportsStore.teams}
                            onChange={id => ReportsStore.setWinner(id)}
                            disableIconColor={true}
                        />
                    </FormCol>
                    <FormCol>
                        <Input
                            type="select"
                            value={ReportsStore.editItem.monster}
                            label="Команда монстр"
                            options={ReportsStore.teams}
                            onChange={id => ReportsStore.setMonster(id)}
                            disableIconColor={true}
                        />
                    </FormCol>
                </FormRow>
                <FormRow>
                    &nbsp;
                </FormRow>
                <Table
                    thead={[
                        {
                            title: 'Порядок',
                            width: '12%',
                        },
                        {
                            title: 'Команда 1',
                            align: 'center'
                        },
                        {
                            title: 'Гол',
                            width: '44px',
                            align: 'center'
                        },
                        {
                            title: 'Гол',
                            width: '44px',
                            align: 'center'
                        },
                        {
                            title: 'Команда 2',
                            align: 'center'
                        },
                        {
                            title: '',
                            width: '40px',
                            align: 'center'
                        },
                    ]}
                    tbody={ReportsStore.editItem.result.map((result, i) => [
                        <Input
                            type="text"
                            value={result.sort}
                            // value={i}
                            onChange={value => ReportsStore.setEditItemResultSort(i, parseInt(value))}
                        />,
                        <Input
                            type="select"
                            value={result.teams[0].id}
                            options={ReportsStore.teams}
                            onChange={id => ReportsStore.setEditItemResultTeam(i, 0, id)}
                            disableIconColor={true}
                        />,
                        <Input
                            type="text"
                            align="center"
                            value={result.teams[0].score}
                            onChange={value => ReportsStore.setEditItemResultTeamScore(i, 0, parseInt(value))}
                        />,
                        <Input
                            type="text"
                            align="center"
                            value={result.teams[1].score}
                            onChange={value => ReportsStore.setEditItemResultTeamScore(i, 1, parseInt(value))}
                        />,
                        <Input
                            type="select"
                            value={result.teams[1].id}
                            options={ReportsStore.teams}
                            onChange={id => ReportsStore.setEditItemResultTeam(i, 1, id)}
                            disableIconColor={true}
                        />,
                        <div className="actions">
                            <Button type="primary" icon="trash" color="red" onClick={() => { ReportsStore.removeEditeItemResult(i) }}></Button>
                        </div>
                    ])}
                />
                <FormHr />
            </FormBody>
            <FormActions>
                <Button type="primary" onClick={onSubmit} icon="check" loading={!loaded}>Применить</Button>
                <Button type="secondary" onClick={cancel}>Отменить</Button>
                <div style={{ flexGrow: 1 }} />
                <Button type="primary" icon="plus" color="purple" onClick={() => { ReportsStore.addEditItemResult() }}>Добавить</Button>
            </FormActions>
        </Form>
    )
})

export default Reports_Edit_Games_Page